// src/App.js

import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './assets/styles.css';
import { ScrollToTop } from './components/ScrollToTop';
import { Home } from './components/Home/home';
import { About } from './components/About/About';
import { Services } from './components/Services/services';
import { Projects } from './components/Projects/projects';
import { Contact } from './components/Contact/contact';
import { Header } from './components/Header/header';
import { Portfolio } from './components/Portfolio/portfolio';
import { Footer } from './components/Footer/footer';
import { WhatsappDialog } from './components/WhatsApp/whatsApp';

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <div className="app-container">
        <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <WhatsappDialog />
      <Footer />
    </div>
  </BrowserRouter>
  );
}

export default App;
