import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import './header.css';

export function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = () => {
    setMobileOpen(false);
  };

  return (
    <AppBar position="sticky" className="header">
      <Toolbar className="d-flex justify-content-between">
        <a className="text-decoration-none" href='/'>
          <figure>
          <img src="brand-logo.png" width="100" alt="" className="header-logo" />
          <figcaption> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; It Solution</figcaption>
          </figure>
        </a>
        <Box className={`header-nav ${mobileOpen ? 'open' : ''}`}>
          <Button color="inherit" onClick={handleMenuItemClick}>
            <Link to="/" className="nav-link">Home</Link>
          </Button>
          <Button color="inherit" onClick={handleMenuItemClick}>
            <Link to="/about" className="nav-link">About</Link>
          </Button>
          <Button color="inherit" onClick={handleMenuItemClick}>
            <Link to="/services" className="nav-link">Services</Link>
          </Button>
          <Button color="inherit" onClick={handleMenuItemClick}>
            <Link to="/portfolio" className="nav-link">Portfolio</Link>
          </Button>
          <Button color="inherit" onClick={handleMenuItemClick}>
            <Link to="/projects" className="nav-link">Projects</Link>
          </Button>
          <Button color="inherit" onClick={handleMenuItemClick}>
            <Link to="/contact" className="nav-link">Contact</Link>
          </Button>
        </Box>
        <button
          className="navbar-toggler menu-icon"
          type="button"
          onClick={handleDrawerToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </Toolbar>
    </AppBar>
  );
}
